const moment = require('moment');

export const fields = {
  status: {
    loading: false,
    disabled: false,
    label: 'Status',
  },
  photo: {
    loading: false,
    disabled: false,
    label: 'Status',
  },
  signedupAt: {
    loading: false,
    disabled: false,
    label: 'Desde',
  },
  buzzer: {
    mask: 'XXXX XXXX XXXX XXXX',
    loading: false,
    disabled: false,
    label: 'Código Buzzer',
    changed: false
  },
  fullname: {
    loading: false,
    disabled: false,
    label: 'Nome Completo',
    changed: false,
    messages: ''
  },
  email: {
    mask: '',
    loading: false,
    disabled: false,
    label: 'Email',
    changed: false,
    messages: ''
  },
  cpf: {
    mask: '###.###.###-##',
    loading: false,
    disabled: false,
    label: 'CPF',
    changed: false,
    messages: ''
  },
  cnh: {
    mask: '###########',
    loading: false,
    disabled: false,
    label: 'CNH',
    changed: false,
    messages: ''
  },
  rg: {
    id: {
      mask: '########-#',
      loading: false,
      disabled: false,
      label: 'RG',
      maxLength: 15,
      rules: [v => v != null && v.length <= 15 || 'Deve conter até 15 caracteres.'],
      changed: true,
      messages: ''
    },
    issuer: {
      loading: false,
      disabled: false,
      label: 'Órgão Expeditor',
      changed: true,
      messages: ''
    }
  },
  phone: {
    mask: '+## (##) #####-####',
    loading: false,
    disabled: false,
    label: 'Celular',
    hint: 'DDD + Telefone',
    changed: false,
    messages: ''
  },
  birthdate: {
    mask: '##/##/####',
    loading: false,
    disabled: false,
    label: 'Data de nascimento',
    hint: 'dd/mm/aaaa',
    changed: false,
    messages: ''
  },
  gender: {
    loading: false,
    disabled: false,
    label: 'Gênero',
    changed: false,
    items: [
      {
        text: 'Feminino',
        value: 'F'
      },
      {
        text: 'Masculino',
        value: 'M'
      },
      {
        text: 'Prefere não dizer',
        value: '-'
      },
    ]
  },
  gps: {
    toggle: true,
    loading: false,
    disabled: true,
    label: 'GPS (Recomendado)',
  },
  push: {
    toggle: true,
    loading: false,
    disabled: true,
    label: 'Notificações (Recomendado)',
  },
  rating: {
    value: null,
    colors: ['red', 'amber', 'lime', 'secondary', 'primary'],
    toggle: true,
    loading: false,
    disabled: false,
    label: 'Destaque',
  },
  tags: {
    value: [],
    toggle: false,
    focus: false,
    loading: false,
    disabled: false,
    label: 'Tags',
  },
  fleet: {
    value: null,
    toggle: true,
    loading: false,
    disabled: false,
    label: 'Frota',
  },
  job: {
    products: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'Produtos Mobees',
      placeholder: 'Quais você tem interesse?',
      success: false,
      rules: [v => !!v && v!=null && v.length>0 || 'Campo obrigatório'],
      items: [
        {
          text: 'Buzzer Digital',
          value: 'BUZZER_DIGITAL'
        },
        {
          text: 'Buzzer Estático',
          value: 'BUZZER_PRINT'
        },
        {
          text: 'Envelopamento - Vidro',
          value: 'WRAPPING_WINDOW'
        },
        {
          text: 'Envelopamento - Carroceria',
          value: 'WRAPPING_BODYWORK'
        },
      ]
    },
    special_projects: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'Projetos Especiais Mobees',
      placeholder: 'Teria interesse em participar?',
      success: false,
      rules: [v => v!=null || 'Campo obrigatório'],
      items: [
        {
          text: 'Tenho interesse',
          value: 1
        },
        {
          text: 'Não tenho interesse',
          value: 0
        },
      ],
    },
    apps: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'Aplicativos de trabalho:',
      placeholder: 'Selecione...',
      success: false,
      rules: [v => !!v && v!=null && v.length>0 || 'Campo obrigatório'],
      items: [
        {
          text: 'Uber',
          value: 'UBER'
        },
        {
          text: '99',
          value: '99'
        },
        {
          text: 'InDriver',
          value: 'INDRIVER'
        },
        {
          text: 'TAXI.RIO',
          value: 'TAXIRIO'
        },
        {
          text: 'Outros',
          value: 'Outros'
        },
        {
          text: 'Nenhum',
          value: 'Nenhum'
        },
      ]
    },
    apps_percent: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'Quanto utiliza cada aplicativo?',
      placeholder: 'Selecione...',
      success: false,
      rules: [v => !!v && v!=null && !Object.values(v).reduce((unset, app) => unset||app.value==null, false) || 'Campo obrigatório'],
      items: [],
      options: [
        10,
        20,
        30,
        40,
        50,
        60,
        70,
        80,
        90,
      ],
    },
    service_model: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'Categoria que trabalha:',
      placeholder: 'Selecione...',
      success: false,
      rules: [v => !!v || 'Campo obrigatório'],
      items: [
        {
          text: 'Popular (Uber X, 99 Pop e/ou similares)',
          value: 'X'
        },
        {
          text: 'Premium (Black, 99 Comfort e/ou similares)',
          value: 'B'
        },
        {
          text: 'Ambas com mais corridas Popular (X)',
          value: 'Xb'
        },
        {
          text: 'Ambas com mais corridas Premium (Black)',
          value: 'Bx'
        },
        {
          text: 'Taxista',
          value: 'Tx'
        },
      ]
    },
    fulltime: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'Motorista de aplicativo 100%?',
      hint: 'Trabalha só com aplicativos (Uber, 99, Cabify...)?',
      success: false,
      rules: [v => v!=null || 'Campo obrigatório'],
      items: [
        {
          text: 'É minha única fonte de renda',
          value: 1
        },
        {
          text: 'Já tenho emprego e é uma renda extra para mim',
          value: 0
        },
      ]
    },
    journey: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'Média de horas rodadas por dia',
      hint: '',
      success: false,
      rules: [v => v!=null || 'Campo obrigatório'],
      items: [
        {
          text: 'Menos que 8 horas',
          value: 2
        },
        {
          text: 'Entre 8 e 12 horas',
          value: 1
        },
        {
          text: 'Mais que 12 horas',
          value: 0
        },
      ]
    }, 
    region: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'Região onde mais roda',
      placeholder: 'Selecione...',
      hint: '',
      success: false,
      rules: [v => v!=null && v.length>=3 || 'Campo obrigatório'],
      items: [
        { header: 'RJ' },
        {
          title: 'Rio de Janeiro/Zona Sul',
          group: 'RJ'
        },
        {
          title: 'Rio de Janeiro/Zona Norte',
          group: 'RJ'
        },
        {
          title: 'Rio de Janeiro/Zona Oeste',
          group: 'RJ'
        },
        {
          title: 'Rio de Janeiro/Centro',
          group: 'RJ'
        },
        {
          title: 'Baixada',
          group: 'RJ'
        },
        {
          title: 'Niterói',
          group: 'RJ'
        },
        { header: 'Outras regiões' },
      ]
    },
    rating: {
      toggle: true,
      mask: '#.##',
      loading: false,
      disabled: false,
      label: 'Nota aplicativo',
      hint: 'Sua nota no principal aplicativo que você roda',
      success: false,
      rules: [v => !!v || 'Campo obrigatório',
        v => /^[0-9]*\.[0-9]{2}$/.test(v) || 'Digite no formato X.XX, por favor. Ex: 4.90'
      ],
    },
    experience: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'Experiência',
      hint: 'Quantos anos como motorista de app?',
      max: moment().diff('2012-01-01', 'months'),
      rules: [v => v!=null || 'Campo obrigatório', v => v>0 || 'Campo obrigatório'],
      
    },
  },
  address: {
    street: {
      loading: false,
      disabled: false,
      label: 'Logradouro',
      hint: 'Ex: Rua da Glória',
      changed: false,
      messages: ''
    },
    number: {
      loading: false,
      disabled: false,
      label: 'Número',
      hint: 'Número da casa ou prédio',
      changed: false,
      messages: ''
    },
    compl: {
      loading: false,
      disabled: false,
      label: 'Complemento',
      hint: 'Ex: Apto 401',
      changed: false,
      messages: ''
    },
    neighborhood: {
      loading: false,
      disabled: false,
      label: 'Bairro',
      hint: '',
      changed: false,
      messages: ''
    },
    zip: {
      mask: '#####-###',
      loading: false,
      disabled: false,
      label: 'CEP',
      hint: '',
      changed: false,
      messages: ''
    },
    city: {
      loading: false,
      disabled: false,
      label: 'Cidade',
      hint: '',
      changed: false,
      messages: ''
    },
    state: {
      toggle: true,
      mask: 'AA',
      loading: false,
      disabled: false,
      label: 'UF',
      hint: '',
      success: false,
      rules: [v => !!v && v.length==2 || 'Campo obrigatório'],
      items: [
        'RO',
        'AC',
        'AM',
        'RR',
        'PA',
        'AP',
        'TO',
        'MA',
        'PI',
        'CE',
        'RN',
        'PB',
        'PE',
        'AL',
        'SE',
        'BA',
        'MG',
        'ES',
        'RJ',
        'SP',
        'PR',
        'SC',
        'RS',
        'MS',
        'MT',
        'GO',
        'DF',
      ]
    },
    country: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'País',
      hint: '',
      success: false,
      rules: [v => !!v && v.length>=3 || 'Campo obrigatório'],
    },
  },
  vehicle: {
    rental: {
      loading: false,
      disabled: false,
      label: 'Nome da Locadora',
      hint: '',
      messages: ''
    },
    parking: {
      loading: false,
      disabled: false,
      label: 'Onde Estaciona',
      items: [
      {
        text: 'Garagem coberta',
        value: 0
      },
      {
        text: 'Garagem descoberta',
        value: 1
      },
      {
        text: 'Rua',
        value: 2
      },
    ]
    },
    plate: {
      loading: false,
      disabled: false,
      label: 'Placa',
      hint: '',
      messages: ''
    },
    chassis: {
      loading: false,
      disabled: false,
      label: 'Chassi',
      hint: '',
      changed: false,
      messages: ''
    },
    color: {
      loading: false,
      disabled: false,
      label: 'Cor',
      hint: '',
      changed: false,
      messages: ''
    },
    fuel: {
      toggle: true,
      loading: false,
      disabled: false,
      label: 'Combustível:',
      placeholder: 'Selecione...',
      success: false,
      rules: [v => !!v && v!=null && v.length>0 || 'Campo obrigatório'],
      items: ['GNV', 'Gasolina', 'Álcool', 'Elétrico']
      },
    brand: {
      loading: false,
      disabled: false,
      label: 'Marca',
      hint: '',
      changed: false,
      messages: ''
    },
    model: {
      loading: false,
      disabled: false,
      label: 'Modelo',
      hint: '',
      changed: false,
      messages: '',
      items: [
        'Agile', 
        'Bolt', 
        'Celta', 
        'Classic',
        'Cobalt',
        'Corsa Classic',
        'Cruze',
        'Equinox',
        'Onix',
        'Onix +',
        'Prisma',
        'Spin',
        'Aircross',
        'C3',
        'C3 Picasso',
        'C4',
        'C4 Lounge',
        '500',
        'Argo',
        'Bravo',
        'Cronos',
        'Doblò',
        'Dolphin',
        'Dolphin Mini',
        'Grand Siena',
        'Idea',
        'Linea',
        'Mobi',
        'Palio',
        'Palio Weekend',
        'Punto',
        'Siena',
        'Toro',
        'Uno',
        'Fusion',
        'Edge',
        'EcoSport',
        'Fiesta',
        'Focus',
        'Ka',
        'Ka +',
        'Accord',
        'City',
        'Civic',
        'CR-V',
        'Fit',
        'HRV',
        'WRV',
        'Azera',
        'Creta',
        'Elantra',
        'HB20',
        'HB20S',
        'HB20X',
        'ix20',
        'ix35',
        'Santa Fé',
        'Sonata',
        'Tucson',
        'Veloster',
        'Veracruz',
        'J3',
        'J5',
        'Bongo',
        'Cadenza',
        'Carens',
        'Carnival',
        'Cerato',
        'Optima',
        'Picanto',
        'Quoris',
        'Rio',
        'Sorento',
        'Soul',
        'Sportage',
        'Stinger',
        'Pajero',
        'Lancer',
        'Grand Livina',
        'Kicks',
        'Leaf',
        'Livina',
        'March',
        'Sentra',
        'Tiida',
        'Versa',
        '2008',
        '207',
        '207 Passion',
        '208',
        '3008',
        '408',
        '5008',
        'Captur',
        'Clio',
        'Duster',
        'Fluence',
        'Kwid',
        'Logan',
        'Sandero',
        'Corolla',
        'Etios',
        'Prius',
        'Yaris',
        'Space Fox',
        'Fox',
        'Gol',
        'Golf',
        'Jetta',
        'Polo',
        'T-Cross',
        'Tiguan',
        'Up',
        'Virtus',
        'Voyage',
        'Outro'
      ],
    },
    year: {
      mask: '####',
      loading: false,
      disabled: false,
      label: 'Ano',
      changed: false,
      messages: ''
    },
    renavam: {
      loading: false,
      disabled: false,
      label: 'Renavam',
      changed: false,
      messages: ''
    },
    isOwner: {
      loading: false,
      disabled: false,
      label: 'Dono',
      success: false,
      rules: [v => v!=null || 'Campo obrigatório'],
      items: [
        {
          text: 'Carro Próprio (no meu nome)',
          value: 1
        },
        {
          text: 'Emprestado',
          value: 0
        },
        {
          text: 'Locadora',
          value: 2
        },
      ]
    },
    ownerName: {
      loading: false,
      disabled: false,
      label: 'Nome do Proprietário',
      changed: false,
      messages: ''
    },
    ownerRg: {
      mask: '########-#',
      loading: false,
      disabled: false,
      maxLength: 9,
      rules: [v => !!v && v != null && v.length > 6 || 'Campo obrigatório'],
      label: 'RG do Proprietário',
      changed: false,
      messages: ''
    },
    ownerOrg_exp: {
      loading: false,
      disabled: false,
      label: 'Orgão Expeditor',
      changed: false,
      messages: ''
    },
    ownerEmail: {
      mask: '',
      loading: false,
      disabled: false,
      label: 'Email do Proprietário',
      changed: false,
      messages: ''
    },
    ownerCpf: {
      mask: '###.###.###-##',
      loading: false,
      disabled: false,
      label: 'CPF do Proprietário',
      changed: false,
      messages: ''
    },
    ownerRelative: {
      loading: false,
      disabled: false,
      label: 'Parentesco do Proprietário',
      changed: false,
      messages: ''
    },
  },
  payment: {
    bankCode: {
      loading: false,
      disabled: false,
      label: 'Código do Banco',
      hint: '',
      changed: false
    },
    bank: {
      loading: false,
      disabled: false,
      label: 'Banco',
      hint: '',
      changed: false
    },
    branch: {
      mask: ['###', '###-#', '###-##'], // mask freezes the browser
      loading: false,
      disabled: false,
      label: 'Agência',
      changed: false
    },
    account: {
      mask: ['###-#', '####-#', '#####-#', '######-#'], // mask freezes the browser
      loading: false,
      disabled: false,
      label: 'Conta Corrente',
      hint: 'Número da sua conta sem o dígito',
      changed: false
    },
    digit: {
      loading: false,
      disabled: false,
      label: 'Dígito da Conta Corrente',
      hint: 'Número da sua conta sem o dígito',
      changed: false
    },
    type: {
      loading: false,
      disabled: false,
      label: 'Tipo de Conta',
      changed: false,
      items: [
        {
          text: 'Poupança',
          value: 'POU'
        },
        {
          text: 'Corrente',
          value: 'COR'
        }
      ]
    },
    compl: {
      loading: false,
      disabled: false,
      label: 'Complemento da Conta Corrente',
      changed: false
    },
  },
};

export const status = [
  { 
    text: 'Registrado',
    value: 'REG',
    disabled: true,
    mustbe: ['DEN']
  },
  {
    text: 'Legalizado', 
    value: 'ACC',
    disabled: true,
    mustbe: ['FDB', 'ENR', 'SIG', 'FDD', 'REA', 'SCH']
  },
  {
    text: 'Avaliado', 
    value: 'FDB',
    disabled: true,
    mustbe: ['ENR', 'FDD', 'REA', 'SIG']
  },
  {
    text: 'Selecionado', 
    value: 'ENR',
    disabled: true,
    mustbe: ['ACC', 'FDB', 'FDD', 'SIG']
  },
  {
    text: 'Aguardando Assinatura', 
    value: 'SIG',
    disabled: true,
    mustbe: ['REA', 'ENR', 'FDD']
  },
  {
    text: 'Documentação Enviada', 
    value: 'FDD',
    disabled: true,
    mustbe: ['ENR', 'REA']
  },
  {
    text: 'Preparado', 
    value: 'REA',
    disabled: true,
    mustbe: ['FDB', 'SCH', 'ENR', 'ACT']
  },
  { 
    text: 'Agendamento',
    value: 'SCH',
    disabled: true,
    mustbe: ['REA', 'FDD', 'ACT']
  },
  { 
    text: 'Operando',
    value: 'ACT',
    disabled: true,
    mustbe: []
  },
  { 
    text: 'Desistente',
    value: 'FOR',
    disabled: true,
    mustbe: []
  },
  { 
    text: 'Bloqueado',
    value: 'DEN',
    disabled: true,
    mustbe: null
  },
]

export const docs = {
  'doc_cnh': {
    title: 'CNH',
    example: 'doc_cnh.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_app_perfil': {
    title: 'Perfil Aplicativo',
    example: 'doc_app_perfil.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas1': {
    title: 'Horas Online (esta semana)',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas2': {
    title: 'Horas Online (*week-1*)',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas3': {
    title: 'Horas Online (*week-2*)',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas4': {
    title: 'Horas Online (*week-3*)',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_veiculo': {
    title: 'Doc. Veículo – CRLV',
    example: 'doc_veiculo.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_veiculo_compra': {
    title: 'Doc. Veículo – Compra/Venda',
    example: 'doc_veiculo.jpg',
    loading: false,
    success: false,
  },
  'doc_residencia': {
    title: 'Comprov. Residência',
    example: 'doc_residencia.jpg',
    loading: false,
    success: false,
  },
  'doc_foto_veiculo1': {
    title: 'Foto Veículo – Dianteira Esquerda',
    example: 'doc_foto_veiculo1.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_foto_veiculo2': {
    title: 'Foto Veículo – Dianteira Direita',
    example: 'doc_foto_veiculo2.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_foto_veiculo3': {
    title: 'Foto Veículo – Traseira Direita',
    example: 'doc_foto_veiculo3.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_foto_veiculo4': {
    title: 'Foto Veículo – Traseira Esquerda',
    example: 'doc_foto_veiculo4.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_rg_prop': {
    title: 'RG Proprietário',
    example: 'doc_rg.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
  'doc_cpf_prop': {
    title: 'CPF Proprietário',
    example: 'doc_cpf.jpg',
    auto_validation: true,
    loading: false,
    success: false,
  },
}

export default {
  fields,
  status,
  docs
}